import "./styles.scss"

import React from "react"

import Button from "components/Button"
import LazyFadeImage from "components/LazyFadeImage"

const Offer = () => {
  return (
    <section className="about-offer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/about/about-offer-image-1.jpg").default
                }
                alt=""
              />
            </LazyFadeImage>
            <h2>Dla klientów</h2>
            <Button to="/dla-klientow/" className="button--bordered">
              Sprawdź ofertę
            </Button>
          </div>

          <div className="col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/about/about-offer-image-2.jpg").default
                }
                alt=""
              />
            </LazyFadeImage>
            <h2>Dla deweloperów</h2>
            <Button to="/dla-deweloperow/" className="button--bordered">
              Sprawdź ofertę
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offer
