import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Grid = () => {
  return (
    <section className="about-grid">
      <div className="container-fluid">
        <div className="row row-main align-items-center">
          <div className="col-xl-4 offset-xl-1 col-lg-6">
            <h2>Marshall Real Estate</h2>
            <p>
              W czasach niepewności i strachu, jakie towarzyszyły nam na
              początku 2020 roku, firma Marshall Real Estate rozpoczynała swoją
              działalność. Okres pandemii COVID-19 i ograniczenia jej
              towarzyszące były wyzwaniem, ale także okazją do udowodnienia
              swojej wartości jako partnera biznesowego. Dziś, z dumą możemy
              pochwalić się portfelem ponad 300 zadowolonych Klientów i
              współpracą z doświadczonymi podmiotami na rynku. Nasz sukces
              zawdzięczamy jasnym zasadom współpracy i dążeniu do długofalowych
              relacji opartych na zaufaniu i wzajemnej satysfakcji.
            </p>
          </div>

          <div className="col-xl-6 offset-xl-1 col-lg-6">
            <div className="row">
              <div className="col-sm-6">
                <div className="about-grid__person">
                  <img
                    src={
                      require("assets/images/about/about-grid-tomasz.jpg")
                        .default
                    }
                    alt=""
                  />
                  <p>
                    Od blisko 17 lat aktywnie pracuje w sektorze finansowym oraz
                    nieruchomościowym. Łącząc pasję do rynku inwestycyjnego z
                    kompetencjami managerskimi budował i zarządzał zespołami o
                    rozproszonych strukturach. Wielokrotnie nagradzany za pracę
                    eksperta oraz managera. W 2018 ukończył studia na Polskiej
                    Akademii Nauk uzyskując dyplom MBA. Fan żużla i miłośnik
                    podróżowania po świecie.
                  </p>
                </div>
                <small>Tomasz Kozioł - Współzałożyciel</small>
              </div>
              <div className="col-sm-6">
                <div className="about-grid__person">
                  <img
                    src={
                      require("assets/images/about/about-grid-karol.jpg")
                        .default
                    }
                    alt=""
                  />
                  <p>
                    Od 2012 r. nieustannie związany z branża finansową i rynkiem
                    nieruchomości. Doświadczony doradca oraz manager. Pierwsze
                    kroki na rynku inwestycyjnym i kapitałowym stawiał w jednej
                    z największych firm doradztwa finansowego, gdzie odpowiadał
                    za prowadzenie oddziału oraz budowanie relacji z klientami z
                    sektora Private oraz Prestige. Ukończył Ican Instytute.
                    Pasjonat rozwoju osobistego i wytrawny szachista.
                  </p>
                </div>
                <small>Karol Szumański - Współzałożyciel</small>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-main align-items-center">
          <div className="col-xl-6 col-md-6">
            <LazyFadeImage>
              <div
                className="about-grid__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/about/about-grid-image.jpg").default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-4 offset-xl-1 col-md-6">
            <h2>Stwarzamy możliwości. Dla Twojego komfortu.</h2>
            <p>
              Zdajemy sobie sprawę, że spełnienie marzenia o posiadaniu{" "}
              wakacyjnej nieruchomości w ulubionym miejscu może być wyczekiwanym
              wydarzeniem w życiu. Wiemy również, jak istotną rolę odgrywa
              posiadanie stabilnego, dodatkowego źródła dochodu. Nieruchomość
              inwestycyjna może dać poczucie bezpieczeństwa Tobie i Twojej
              rodzinie. Jesteśmy tutaj by pomóc Ci w realizacji celu.
            </p>
            <p>
              Pragniemy podzielić się naszą wiedzą o rynku nieruchomości
              premium. Razem z Tobą przejdziemy przez proces wyboru i zakupu
              apartamentu. Naszym celem nie jest jednorazowa transakcja. Zależy
              nam, by zbudować opartą na zaufaniu relację na lata.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
