import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Partners from "components/Partners"
import Projects from "components/Projects"

import { Grid, Tiles, Offer } from "page_components/about"

const About = () => {
  const title = "O nas"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Dowiedz się więcej o naszej firmie i przekonaj się, dlaczego warto inwestować z nami! Zadowolenie naszych klientów jest dla nas najważniejsze. Zaufaj naszemu doświadczeniu.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Grid />
      <Tiles />
      <Partners />
      <Projects />
      <Offer />
    </Layout>
  )
}

export default About
