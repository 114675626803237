import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import LazyFadeImage from "components/LazyFadeImage"

const Tiles = () => {
  const data = [
    {
      image: require("assets/images/about/about-tiles-image-1.jpg").default,
      title: "Wybór apartamentu",
      to: "/portfel-nieruchomosci/",
    },
    {
      image: require("assets/images/about/about-tiles-image-2.jpg").default,
      title: "Aranżacja wnętrz",
      to: "/dla-klientow/zarzadzanie-apartamentem-wykonczenie-wnetrz/",
    },
    {
      image: require("assets/images/about/about-tiles-image-3.jpg").default,
      title: "Profesjonalna usługa najmu",
      to: "/dla-klientow/zarzadzanie-apartamentem-wykonczenie-wnetrz/",
    },
    {
      image: require("assets/images/about/about-tiles-image-4.jpg").default,
      title: "Finansowanie i doradztwo podatkowe",
      to: "/dla-klientow/doradztwo-podatkowe/",
    },
  ]

  return (
    <section className="about-tiles">
      <div className="container-fluid">
        <h2>Dobrze jest mieć wybór, dlatego my dajemy go naszym Klientom</h2>
        <p>
          Z czasem wszystko się zmienia. Nasze potrzeby również. Dlatego
          apartamenty, które znajdziesz w naszej ofercie, w zdecydowanej
          większości oferują pełną swobodę dotyczącą ich użytkowania. Oznacza
          to, że sam zdecydujesz czy Twoja nieruchomość będzie nastawiona na
          generowanie zyskówpochodzących z najmu, czy zostanie przeznaczona do
          użytku prywatnego . Bez względu na to, czy szukasz swojego drugiego
          domu w miejscowości turystycznej, czy interesuje Cię osiąganie
          stabilnych zysków z najmu krótkoterminowego - dobrze trafiłeś.
          <br />
          Oferujemy wsparcie w zakresie:
        </p>

        <div className="row">
          {data.map((item, index) => (
            <div className="col-lg-3 col-sm-6" key={index}>
              <Link to={item.to} className="about-tiles__item">
                <div className="about-tiles__item-image">
                  <LazyFadeImage>
                    <img src={item.image} alt="" />
                  </LazyFadeImage>
                </div>
                <strong>{item.title}</strong>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Tiles
